import { createSlice } from '@reduxjs/toolkit';

const tasksByOrderSlice = createSlice({
  name: 'tasksByOrder',
  initialState: { loading: false, data: [] as any },
  reducers: {
    setTasksByOrderState: (state, action) => ({ ...state, ...action.payload }),
    setTasksByOrder: (state, action) => ({ ...state, ...action.payload }),
    addTasksByOrderData: (state, action) => {
      state.data.unshift(action.payload);
    },
    updateTasksByOrderData: (state, action) => {
      state.data = state.data.map((task: { id: number }) =>
        task.id === action.payload.id ? { ...action.payload } : task
      );
    },
    deleteTasksByOrderData: (state, action) => {
      state.data = state.data.filter((task: { id: number }) => task.id !== action.payload.id);
    },
  },
});

export const {
  loadTasksByOrder = 'tasksByOrder/LOAD_TASKS_BY_ORDER',
  updateTasksByOrder = 'tasksByOrder/UPDATE_TASKS_BY_ORDER',
  deleteTasksByOrder = 'tasksByOrder/DELETE_TASKS_BY_ORDER',
  setTasksByOrderState,
  setTasksByOrder,
  updateTasksByOrderData,
  deleteTasksByOrderData,
  addTasksByOrderData,
}: any = tasksByOrderSlice.actions;

export default tasksByOrderSlice.reducer;
