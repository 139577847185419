import { all, put, takeEvery, call } from 'redux-saga/effects';
import { getOrderApi, getOrdersApi, getOrdersByFilterApi } from '../../services/orderService';
import { loadOrder, setOrder, setOrderState } from '../reducers/orderReducer';
import { loadOrders, setOrders, setOrdersState } from '../reducers/ordersReducers';

function* LOAD_ORDERS({ payload }: Record<string, never>) {
  yield put(setOrdersState({ loading: true }));

  let orders: Record<string, never>;

  if (payload) {
    orders = yield call(() => getOrdersByFilterApi(payload));
  } else {
    orders = yield call(() => getOrdersApi());
  }

  // if (orders.count) {
  //   yield put(setOrder({ data: orders.data[0] }));
  // } else {
  //   yield put(setOrder({ data: { id: 0 } }));
  // }
  // yield put(setOrder({ data: { id: 0 } }));

  yield put(setOrders({ count: orders.count, data: orders?.data || [] }));

  yield put(setOrdersState({ loading: false }));
}

function* LOAD_ORDER({ id }: { id: number }) {
  yield put(setOrderState({ loading: true }));

  const order: Record<string, never> = yield call(() => getOrderApi(id));

  if (order) {
    yield put(setOrder(order));
  }

  yield put(setOrderState({ loading: false }));
}

export default function* rootSaga(): any {
  yield all([takeEvery(loadOrders, LOAD_ORDERS), takeEvery(loadOrder, LOAD_ORDER)]);
}
