import React, { useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import './loginPage.styles.css';
// import { login } from '../../auth/services/auth.service';
import { Form } from 'react-bootstrap';
import { connect } from 'react-redux';
import { AuthProps } from '../../../types/states';
import TextInput from '../../common/input/TextInput';
import { login } from '../../../services/auth/auth.service';
import { setAuth } from '../../../store/reducers/authReducer';
// import { setAuth } from '../../store/reducers/authReducer';
type StateProps = {
  auth: { data: AuthProps };
  // activeChat: { data: { type: string; id: string } };
};
const required = (value: any) => {
  if (!value) {
    return (
      <div className="alert alert-danger" role="alert">
        This field is required!
      </div>
    );
  }
};
const mapStateToProps = ({ auth }: StateProps) => ({ auth });

const Login = ({ auth }: StateProps) => {
  const form = useRef();
  const checkBtn = useRef();

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();

  React.useEffect(() => {
    if (localStorage.getItem('user')) {
      window.location.replace('/');
    }
  }, [auth]);

  const onChangeEmail = (e: any) => {
    const email = e.target.value;
    setEmail(email);
  };

  const onChangePassword = (e: any) => {
    const password = e.target.value;
    setPassword(password);
  };
  const handleLogin = (e: any) => {
    e.preventDefault();

    setLoading(true);

    // form.current.validateAll();

    if (email && password) {
      login(email, password)
        .then((res) => {
          localStorage.setItem('user', JSON.stringify(res));

          setLoading(false);
          dispatch(setAuth(res));
        })
        .catch((err) => {
          setLoading(false);
        });
    } else {
      setLoading(false);
    }
  };

  return (
    <div className="signInPage d-flex align-items-center justify-content-center h-100">
      <div className="signInForm">
        <div className="login_logo">
          <img
            className="mb-30"
            src="/static/logo-long.png"
            style={{ maxHeight: '100px' }}
            alt=""
          />
        </div>

        <Form>
          <div className="row form-group input-wrap">
            <div className="col-sm-12 col-md-3">
              <label className="fs-xl" htmlFor="email">
                Username <span className="text-danger">*</span>
              </label>
            </div>
            <div className="col-sm-12 col-md-9">
              <div className="position-relative">
                <TextInput
                  type="text"
                  name="email"
                  value={email}
                  variant="outlined"
                  onChange={onChangeEmail}
                  className="form-control fs-xl"
                  placeholder="Enter your Username"
                />
              </div>
            </div>
          </div>

          <div className="row form-group input-wrap">
            <div className="col-sm-12 col-md-3">
              <label className="fs-xl" htmlFor="password">
                Password <span className="text-danger">*</span>
              </label>
            </div>
            <div className="col-sm-12 col-md-9">
              <div className="position-relative">
                <TextInput
                  type="password"
                  className="form-control fs-xl"
                  name="password"
                  variant="outlined"
                  value={password}
                  onChange={onChangePassword}
                  placeholder="Your password"
                />
              </div>
            </div>
          </div>
          {/* <div class="forgot-pass">
              <Link to="/">Forgot Password?</Link>
            </div> */}
          <div className="action-wrap mb-50">
            <button
              type="submit"
              className="btn btn-primary d-inline-flex align-items-center justify-content-right h-49 fs-xl"
              disabled={loading}
              onClick={(e) => handleLogin(e)}
            >
              {loading && <span className="spinner-border spinner-border-sm mr-2"></span>}
              Sign In
            </button>
          </div>
          {/* <h5 class="signup text-center">
            Don't have an account?<Link to="/register">Sign Up</Link>
          </h5>
          {message && (
            <div className="form-group">
              <div className="alert alert-danger" role="alert">
                {message}
              </div>
            </div>
          )} */}
        </Form>
      </div>
    </div>
  );
};

export default connect(mapStateToProps)(Login);
