import { createSlice } from '@reduxjs/toolkit';

type SliceState = { loading: boolean; data: Record<string, any> };

const companySlice = createSlice({
  name: 'company',
  initialState: {
    loading: false,
    data: {},
  } as SliceState,
  reducers: {
    setcompanyState: (state, action) => ({ ...state, ...action.payload }),
    setcompany: (state, action) => {
      return { ...state, data: action.payload };
    },
  },
});

export const { setcompany, setcompanyState } = companySlice.actions;

export default companySlice.reducer;
