import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import DrawerMenuItem from '../common/navItem/DrawerMenuItem';
import { AuthProps } from '../../types/states';
import { toolsReportTableFields } from '../../libs/tools_report_table_field';
import React, { useState } from 'react';
import { basename, isAdmin, isSuperAdmin } from '../../utils/constant';
import DrawerSubMenuItem from '../common/navItem/DrawerSubMenuItem';

type StateProps = {
  auth?: { data: AuthProps };
  steps?: {
    data: {
      id: number;
      company_id: number;
      step_name: string;
      created_at: string;
      updated_at: string;
    }[];
  };
};

const mapStateToProps = ({ auth, steps }: StateProps) => ({
  auth,
  steps,
});

interface IDrawerMenuProps extends StateProps {
  dispatch: any;
  socket?: any;
  isShown: boolean;
}

const DrawerMenu = ({ steps, auth, isShown }: IDrawerMenuProps & RouteComponentProps) => {
  return (
    <div className="drawerMenu" style={{ overflowX: 'hidden' }}>
      {/* <DrawerMenuItem
        link={`${basename}/`}
        title="Dashboard"
        className="mt-3"
        icon="fas fa-chart-line me-3"
        isShown={isShown}
      /> */}

      {/* <DrawerMenuItem
        link={`${basename}/taskmanagement`}
        title="Task Management"
        icon="fas fa-stopwatch me-3"
        isShown={isShown}
      /> */}
      {/* {isSuperAdmin(auth?.data?.user || {}) && (
        <DrawerMenuItem
          link={`${basename}/users`}
          title="User Management"
          icon="fas fa-users me-3"
          isShown={isShown}
        ></DrawerMenuItem>
      )} */}
      <DrawerMenuItem
        link={`${basename}/rfq-list`}
        title="RFQ Management"
        icon="fas fa-window-restore me-3"
        isShown={isShown}
      ></DrawerMenuItem>
      {isSuperAdmin(auth?.data?.user || {}) && (
        <DrawerMenuItem
          link={`${basename}/users`}
          title="User Management"
          icon="fas fa-users me-3"
          isShown={isShown}
        ></DrawerMenuItem>
      )}

      {/* <DrawerMenuItem
        link={`${basename}/orders`}
        title="Order Management"
        icon="fas fa-book me-3"
        isShown={isShown}
      /> */}
    </div>
  );
};

export default withRouter(connect(mapStateToProps)(DrawerMenu));
