import React from 'react';
import { withRouter, RouteComponentProps } from 'react-router';

interface IDrawerMenuItemProps {
  title?: string;
  icon?: any;
  className?: string;
  actionArea?: string | JSX.Element;
  link?: string;
  defaultActive?: boolean;
  isShown: boolean;
}

const DrawerMenuItem: React.FC<RouteComponentProps & IDrawerMenuItemProps> = ({
  title,
  icon,
  children,
  className = '',
  actionArea = '',
  history,
  link,
  defaultActive = false,
  isShown,
}) => {
  const [isActive, setIsActive] = React.useState(defaultActive);
  return (
    <div className={`drawerMenuItem ${isActive && children ? 'active' : ''} ${className}`}>
      <div
        style={{
          backgroundColor: history?.location?.pathname == link ? 'rgb(36 120 143 / 1)' : '',
        }}
        onClick={() => setIsActive(!isActive)}
        className="menuTitle d-flex align-items-center justify-content-between rounded p-3"
      >
        <div
          className="title d-inline-flex align-items-center"
          onClick={() => !link && setIsActive(!isActive)}
        >
          <i className={icon}></i>
          {/* @ts-ignore */}
          <h5 className="mb-0 cursor-pointer" onClick={() => link && history.push(link)}>
            {isShown ? title : ''}
          </h5>
        </div>
        {actionArea}
      </div>
      {isShown && (
        <div className="childrenWrapper overflow-hidden">
          <div id="menuChildren">{children}</div>
        </div>
      )}
    </div>
  );
};
export default withRouter(DrawerMenuItem);
