import { createSlice } from '@reduxjs/toolkit';

const tasksSlice = createSlice({
  name: 'tasks',
  initialState: { loading: false, activeTask: {}, data: [] as any },
  reducers: {
    setTasksState: (state, action) => ({ ...state, ...action.payload }),
    setTasks: (state, action) => ({ ...state, ...action.payload }),
    setActiveTask: (state, action) => {
      state.activeTask = action.payload;
    },
    updateTasksData: (state, action) => {
      state.data = state.data.map((task: { id: number }) =>
        task.id === action.payload.id ? { ...action.payload } : task
      );
    },
  },
});

export const {
  loadTasks = 'tasks/loadTasks',
  updateTasks = 'tasks/updateTasks',
  loadTandA = 'tasks/loadTandA',
  setTasksState,
  setActiveTask,
  updateTasksData,
  setTasks,
}: any = tasksSlice.actions;

export default tasksSlice.reducer;
