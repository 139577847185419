import apiClient from './axios';

export async function getOrdersApi(): Promise<string> {
  const endpoint = '/orders/order_status/active/';

  return apiClient
    .get(endpoint)
    .then((response) => {
      return response.data;
    })
    .catch(() => {
      return false;
    });
}

export async function getOrdersByFilterApi(filters: {
  status: string;
  search: string;
}): Promise<string> {
  let endpoint = `/orders/order_status/${filters.status}`;
  if (filters.search) {
    endpoint += `/search/${filters.search}`;
  } else {
    endpoint += '/';
  }

  return apiClient
    .get(endpoint)
    .then((response) => {
      return response.data;
    })
    .catch(() => {
      return false;
    });
}

export async function getOrderApi(id: number): Promise<string> {
  const endpoint = `/orders/${id}`;

  return apiClient
    .get(endpoint)
    .then((response) => {
      return response.data;
    })
    .catch(() => {
      return false;
    });
}

export async function updateOrderApi(id: number, data: Record<string, any>): Promise<string> {
  const endpoint = `/orders/${id}/`;

  return apiClient.put(endpoint, data);
}

export async function addOrderApi(data: Record<string, any>): Promise<any> {
  const endpoint = `/orders/`;

  return apiClient.post(endpoint, data);
}

export async function addUserApi(data: Record<string, never>): Promise<string> {
  const endpoint = `/adduser/`;
  return apiClient.post(endpoint, data);
}
