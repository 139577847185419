import { all, call, put, takeEvery } from 'redux-saga/effects';
import { getContactsApi } from '../../services/contactService';
import { loadContact, setContact, setContactState } from '../reducers/contactReducer';

function* LOAD_CONTACT() {
  yield put(setContactState({ loading: true }));
  const contacts: Record<string, never> = yield call(() => getContactsApi());
  if (contacts) {
    // console.log(contacts);
    yield put(setContact(contacts));
  }

  yield put(setContactState({ loading: false }));
}

export default function* rootSaga(): any {
  yield all([takeEvery(loadContact, LOAD_CONTACT)]);
}
