import { UsersProps } from '../types/states';

export const MATERIAL_CATEGORY_OPTIONS = [
  { title: 'Yarn', value: 'yarn' },
  { title: 'Accessories', value: 'accessories' },
];

export const UNIT_OPTIONS = [
  { value: 'LBS', title: 'LBS' },
  { value: 'KGS', title: 'KGS' },
  { value: 'PICES', title: 'PICES' },
];

export const MATERIAL_TYPES = [
  { title: 'Order Stock', value: 'order_stock' },
  { title: 'General Stock', value: 'general_stock' },
];

export const avatar_placeholder = 'https://omd.sgp1.cdn.digitaloceanspaces.com/user.png';
export const backoffice = 5771;
export const basename = '';
export const rfq_api_url = 'https://mpanel.merchantbay.com/api';
// export const rfq_api_url = 'http://localhost:4444/api';
export const rfq_app_url = `${rfq_api_url}/app`;
export const rfq_supplierlist_url = `${rfq_api_url}/supplierlist`;
export const rfq_list_api = `${rfq_api_url}/rfqlist/all`;
export const rfq_attachment_api = `${rfq_api_url}/attachment`;
export const assigned_rfq_list =
  'https://rfq.merchantbay.com/api/quotation/status/all/filter/null/page/1/limit/10000000';
export const update_rfq = 'https://rfq.merchantbay.com/api/quotation/';
// 'http://localhost:8888/api/quotation/status/all/filter/null/page/1/limit/10000000';
// export const basename = '/console';
export const isSuperAdmin = (user: any) => {
  return user?.is_superuser;
};
export const isAdmin = (user: any) => {
  return user?.is_admin;
};
export const headers = () => {
  // @ts-ignore
  const user = JSON.parse(localStorage.getItem('user'));
  return {
    'Content-Type': 'application/json',
    Authorization: 'Bearer ' + user?.access,
  };
};
