import React from 'react';
import DrawerMenu from '../menu/DrawerMenu';

type LayoutDrawerProps = {
  socket: any;
  isDrawerOpen: boolean;
  setIsDrawerOpen: (val: boolean) => void;
};

const LayoutDrawer = ({
  isDrawerOpen,
  setIsDrawerOpen,
  socket,
}: LayoutDrawerProps): JSX.Element => {
  const [isShown, setIsShown] = React.useState(false);
  return (
    <div className={`layoutDrawer h-100 ${isDrawerOpen ? 'border-end' : ''}`}>
      <div
        className="open-button position-absolute cursor-pointer"
        onClick={() => setIsDrawerOpen(!isDrawerOpen)}
      >
        <i className={`fas fa-sort-up ${isDrawerOpen ? '' : 'closed'}`}></i>
      </div>

      <div
        className={`omd-layout-drawer-wrapper overflow-auto h-100 ${isDrawerOpen ? '' : 'closed'}`}
        onMouseEnter={() => setIsShown(true)}
        onMouseLeave={() => setIsShown(false)}
      >
        <div className="omd-layout-drawer-inner py-3">
          <DrawerMenu socket={socket} isShown={isShown} />
        </div>
      </div>
    </div>
  );
};

export default LayoutDrawer;
