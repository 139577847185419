import { ContactProps } from '../types/states';
import chatServerClient from './axios/chatServerClient';
import rfqClient from './axios/rfqClient';

export async function getMessageApi(payload: ContactProps): Promise<string> {
  let url = undefined;
  if (payload?.type == 'DM') {
    url = `/omd/messages/user/${payload?.user_ids[0]},${payload?.user_ids[1]}/order/0`;
  } else if (payload?.type == 'GM') {
    url = `/omd/messages/user/0/order/${payload?.id}`;
  }

  return url
    ? rfqClient
        .get(url)
        .then((response: any) => {
          console.log(response.data);
          return response.data;
        })
        .catch(() => {
          return false;
        })
    : false;
}

export async function sendFileMessageApi(data: Record<string, any>): Promise<string> {
  return chatServerClient
    .post(`/messages`, data)
    .then((response) => {
      return response.data;
    })
    .catch(() => {
      return false;
    });
}
