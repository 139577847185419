import React from 'react';
import {
  Bell,
  // BookOpen,
  Edit3,
  LogOut,
  Settings,
  User,
  Users,
} from 'react-feather';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import IconBadge from '../common/badge/IconBadge';
import Button from '../common/button/Button';
import Notification from '../common/notification/Notification';
import Modal from '../common/modal/Modal';
import CalendarWrapper from '../common/wrapper/CalendarWrapper';
import { isTheUserAdmin, logout } from '../../utils/authUtils';
import { AuthProps, NotificationProps, UsersProps } from '../../types/states';
import { basename, isSuperAdmin } from '../../utils/constant';
import { loadNotification } from '../../store/reducers/notificationReducer';

type StateProps = {
  auth: { data: AuthProps };
  users: { data: UsersProps[] };
  notification: { data: NotificationProps[]; unseenNotification: { count: number } };
};
interface IUserMenu extends StateProps {
  dispatch: any;
}

const mapStateToProps = ({ auth, users, notification }: StateProps) => ({
  auth,
  users,
  notification,
});

const UserMenu = ({ dispatch, auth, users, notification }: IUserMenu) => {
  // const [isShowNotification, setIsShowNotification] = React.useState(false);
  const [isCalendarModalOpen, setIsCalendarModalOpen] = React.useState(false);
  const [isCalendarOpen, setIsCalendarOpen] = React.useState(false);
  const [isCalendarSection, setIsCalendarSection] = React.useState(true);
  const [isTaskSelected, setIsTaskSelected] = React.useState(false);

  const {
    data: { user },
  } = auth;

  const filteredUser = (Array.isArray(users?.data) &&
    users?.data?.filter((item) => item?.id === user?.id)[0]) || {
    user_picture: `${basename}/static/user/user.png`,
  };

  return (
    <>
      <li className="userMenu d-flex ">
        <div className="align-items-center d-inline-flex">
          <div className="user-details text-left">
            <p className="user-name mb-1 d-flex align-items-center">
              {(user && user['name']) || ''}
              {/* <i className="fas fa-chevron-down"></i> */}
            </p>
            <p className="user-job-title mb-2">{user?.user_type}</p>
            {/* <Button
              size="sm"
              color="theme-primary"
              className="d-inline-block"
              onClick={() => {
                setIsCalendarModalOpen(true);
                setTimeout(() => {
                  setIsCalendarOpen(true);
                }, 500);
              }}
            >
              My Calendar
            </Button> */}
          </div>
          <div className="notificationBell px-2 d-flex align-items-end align-self-stretch dropdown">
            <span
              // onClick={() =>
              //   dispatch({
              //     type: loadNotification,
              //     payload: { id: user?.id },
              //   })
              // }
              className="dropdown-toggle"
              id="dropdownMenuButton1"
              data-bs-toggle="dropdown"
              aria-expanded="false"
              role="button"
            >
              {/* <IconBadge count={notification?.unseenNotification?.count}>
                <Bell />
              </IconBadge> */}
            </span>
            <div className="dropdown-menu border-0" aria-labelledby="dropdownMenuButton1">
              <Notification />
            </div>
          </div>
          <span className="img-80 onhover-dropdown">
            <span className=" object-fit-cover rounded-circle blur-up lazyloaded overflow-hidden h-100 w-100 d-block">
              <img
                className="h-100 w-100 object-fit-cover"
                src={user?.user_picture ? user?.user_picture : `${basename}/static/user/user.png`}
                alt="header-user"
              />
            </span>

            <ul
              className="profile-dropdown onhover-show-div p-20 profile-dropdown-hover"
              style={{ width: 200 }}
            >
              {/* <li className={`${!isTheUserAdmin() ? 'pb-10' : ''}`}>
                <Link to={`${basename}/profile`}>
                  <User /> Edit Profile
                </Link>
              </li> */}
              {/* {isTheUserAdmin() && (
                <li className="pb-10">
                  <Link to={`${basename}/settings`}>
                    <Settings /> Settings
                  </Link>
                </li>
              )} */}
              {isSuperAdmin(auth?.data?.user) && (
                <li>
                  <Link to={`${basename}/users`}>
                    <Users /> User Management
                  </Link>
                </li>
              )}
              <hr className="my-0" />
              {/* <li
                onClick={() =>
                  window.location.assign(`${process.env.REACT_APP_SSO_BASE_URL}passwordreset`)
                }
              >
                <Edit3 /> Change Password
              </li> */}
              <li>
                <Link to="/" onClick={() => logout()}>
                  <LogOut /> Log out
                </Link>
              </li>
            </ul>
          </span>
          <div className="dotted-animation">
            <span className="animate-circle"></span>
            <span className="main-circle"></span>
          </div>
        </div>
      </li>
      <Modal
        visible={isCalendarModalOpen}
        onClose={() => {
          setIsCalendarModalOpen(false);
          setIsCalendarOpen(false);
          setIsCalendarSection(true);
        }}
        resetOnLoad
        customStyles={{
          width: isCalendarSection ? 'calc(100% - 30px)' : 1200,
          overflow: 'auto',
          height: 'calc(100vh - 30px)',
          transition: '.3s',
        }}
      >
        <div className="d-flex justify-content-between">
          <h4 className={isCalendarSection ? 'me-4' : ''}>{isCalendarSection ? 'Calendar' : ''}</h4>
        </div>
        {isCalendarSection ? (
          isCalendarOpen && <CalendarWrapper />
        ) : (
          <div style={{ height: 'calc(100% - 32px)' }}>
            {isTaskSelected ? (
              <>
                <i
                  className={`fas fa-arrow-left text-green cursor-pointer`}
                  onClick={() => {
                    setIsTaskSelected(false);
                  }}
                ></i>

                {/* <TaskCommentCard /> */}
              </>
            ) : (
              <>
                {/* <h3 className="mb-4">Add New Task in {order?.data?.order_number}</h3> */}
                {/* <TimeAndActionForm singleInput setIsTaskSelected={setIsTaskSelected} /> */}
              </>
            )}
          </div>
        )}
      </Modal>
      {/* <Rodal
        showMask={true}
        height={500}
        width={400}
        visible={passwordModal}
        onClose={() => setPasswordModal(false)}
      >
        <EditMyPassword />
      </Rodal> */}
    </>
  );
};

export default connect(mapStateToProps)(UserMenu);
