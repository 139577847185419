import { ReactText } from 'react';
import { toast } from 'react-toastify';
import { POSITION } from 'react-toastify/dist/utils';

export function triggerChatNotification(text: string): ReactText {
  return toast(text);
}
export function triggerInfoNotification(text: string): ReactText {
  return toast(text, {
    className: 'Toastify__toast-theme--colored Toastify__toast--info ',
    progressClassName: 'Toastify__progress-bar-theme--colored Toastify__progress-bar--info',
  });
}
export function triggerSuccessNotification(text: string): ReactText {
  return toast(text, {
    className: 'Toastify__toast-theme--colored Toastify__toast--success ',
    progressClassName: 'Toastify__progress-bar-theme--colored Toastify__progress-bar--success',
  });
}
export function triggerWarningNotification(text: string): ReactText {
  return toast(text, {
    className: 'Toastify__toast-theme--colored Toastify__toast--warning ',
    progressClassName: 'Toastify__progress-bar-theme--colored Toastify__progress-bar--warning',
  });
}
export function triggerErrorNotification(text: string): ReactText {
  return toast(text, {
    className: 'Toastify__toast-theme--colored Toastify__toast--error ',
    progressClassName: 'Toastify__progress-bar-theme--colored Toastify__progress-bar--error',
  });
}
