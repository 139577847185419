import { UsersByOrderProps } from '../types/states';
import { isAdmin, isSuperAdmin } from '../utils/constant';
import apiClient from './axios';
export async function getUsersApi(): Promise<string> {
  const user: any = JSON.parse(localStorage.getItem('user') || '{}');
  if (isSuperAdmin(user?.user)) {
    return apiClient
      .get(`/users/`)
      .then((response) => {
        return response.data;
      })
      .catch(() => {
        return false;
      });
  } else {
    return apiClient
      .get(`/company/users/`)
      .then((response) => {
        return response.data;
      })
      .catch(() => {
        return false;
      });
  }
  return '';
}
export async function getUsersByOrderApi(id: number): Promise<string> {
  return id
    ? apiClient
        .get(`/getuserbyorder/${id}`)
        .then((response) => {
          return response.data;
        })
        .catch(() => {
          return false;
        })
    : false;
}
export async function addUsersToOrderApi(
  orderId: number,
  userId: number,
  userName: string,
  email: string,
  role: string
): Promise<{ data: UsersByOrderProps }> {
  return orderId
    ? apiClient
        .post(`/user/invite/`, {
          order_ids: [orderId],
          user_ids: [userId],
          user_name: userName,
          user_email: email,
          user_role: role,
        })
        .then((response) => {
          return response.data;
        })
        .catch(() => {
          return false;
        })
    : false;
}
export async function removeUsersFromOrderApi(
  orderId: number,
  userId: number,
  permissionId: number
): Promise<{ data: UsersByOrderProps }> {
  return orderId
    ? apiClient
        .delete(`/orders/${orderId}/users/${userId}/permission/${permissionId}`)
        .then((response) => {
          return response.data;
        })
        .catch(() => {
          return false;
        })
    : false;
}
