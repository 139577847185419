import { all, put, takeEvery, call } from 'redux-saga/effects';
import { deleteQCApi, getQualityControlDataApi } from '../../services/qualityControlDataService';
import { triggerErrorNotification, triggerSuccessNotification } from '../../utils/notifications';
import {
  deleteQualityControlData,
  loadQualityControlData,
  removeQualityControlData,
  setQualityControlData,
  setQualityControlDataState,
} from '../reducers/qualityControlDataReducer';

function* LOAD_QUALITY_CONTROL_DATA({ payload }: any) {
  yield put(setQualityControlDataState({ loading: true }));
  const tasks: Record<string, never> = yield call(() => getQualityControlDataApi(payload));
  if (tasks) {
    yield put(setQualityControlData({ count: tasks.count, data: tasks.data }));
  }

  yield put(setQualityControlDataState({ loading: false }));
}
function* DELETE_QUALITY_CONTROL_DATA({ payload }: { payload: { id: number } }) {
  yield put(setQualityControlDataState({ loading: true }));

  // eslint-disable-next-line no-restricted-globals
  if (confirm('Are you sure you want to delete this?')) {
    const tasks: Record<string, never> = yield call(() => deleteQCApi(payload.id));
    if (tasks) {
      yield put(removeQualityControlData(payload.id));
      triggerSuccessNotification('Successfully deleted this data.');
    } else {
      triggerErrorNotification('There is some error.');
    }
  }

  yield put(setQualityControlDataState({ loading: false }));
}

export default function* rootSaga(): any {
  yield all([
    takeEvery(loadQualityControlData, LOAD_QUALITY_CONTROL_DATA),
    takeEvery(deleteQualityControlData, DELETE_QUALITY_CONTROL_DATA),
  ]);
}
