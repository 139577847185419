import RootLayout from './components/common/layout/RootLayout';
import { Redirect, Route, Router, Switch, useHistory } from 'react-router-dom';
import React, { Suspense } from 'react';
import { connect } from 'react-redux';
import { loadSteps } from './store/reducers/stepsReducer';
import { setAuth } from './store/reducers/authReducer';
import { setUnseenNotification } from './store/reducers/notificationReducer';

import { SocketContext, SockteProvider } from './libs/SockteContext';
import { Routes } from './routes';
import NotFound from './pages/NotFound';
import { getActiveThread } from './utils/threadUtils';
import { increaseUnseenThreadsCount, setUnseenThreadsCount } from './store/reducers/threadsReducer';
// @ts-ignores
import LoadingScreen from 'react-loading-screen';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { AuthProps } from './types/states';
import { loadOrders } from './store/reducers/ordersReducers';
import { loadUsers } from './store/reducers/usersReducer';
import { addMessage } from './store/reducers/messageReducer';
import { Socket } from 'socket.io-client';
import { basename, rfq_api_url } from './utils/constant';
import { addrfqMessage } from './store/reducers/rfqmessageReducer';
import { triggerChatNotification, triggerInfoNotification } from './utils/notifications';
import { getUserById } from './utils/userUtils';
import store from './store/store';
import Iframe from 'react-iframe';
import apiClient from './services/axios';
import { setcompany } from './store/reducers/companyInfoReducer';
import Login from './components/pages/auth/Login';
import axios from 'axios';
import '../src/assets/scss/style.scss';

type StateProps = {
  auth: { data: AuthProps };
  // activeChat: { data: { type: string; id: string } };
};
interface IAppProps extends StateProps {
  dispatch: any;
}

const mapStateToProps = ({ auth }: StateProps) => ({ auth });

function App({ dispatch, auth }: IAppProps) {
  const socket: Socket = React.useContext(SocketContext);
  const [pageLoading, setPageLoading] = React.useState(false);

  const router = Routes();

  const history = useHistory();

  React.useEffect(() => {
    axios.get(rfq_api_url + '/rfqlist');
    // axios.get(rfq_api_url + '/supplierlist');
    dispatch({ type: loadUsers });
  }, []);

  React.useEffect(() => {
    setPageLoading(true);

    const search = window.location.search;
    const params = new URLSearchParams(search);
    const token = params?.get('token');
    if (token) {
      try {
        const user: any = JSON.parse(atob(token?.split('.')?.[1] || '{}'));
        const userData = { access: token, user: user };

        // localStorage.setItem('user', atob(token));
        // const userObject = JSON.parse(atob(token));
        localStorage.setItem('user', JSON.stringify(userData));
        const userObject = userData;
        localStorage.setItem('factory_type', userObject?.user?.company?.company_type || 'sweater');
        if (userObject?.user?.is_admin && userObject?.user?.company?.tour === false) {
          // history.push('/initialConfiguration/companyOnboarding');
        }
      } catch (error) {
        console.log(error);
      }
    } else {
      const user = JSON.parse(localStorage.getItem('user') || '{}');
      if (!user) {
        setTimeout(function () {
          history.push('/login');
        }, 2000);
      } else if (user?.user?.is_admin && user?.user?.company?.tour === false) {
        setTimeout(function () {
          // history.push('/initialConfiguration/companyOnboarding');
          setPageLoading(false);
        }, 2000);
      }
    }

    if (localStorage.getItem('user')) {
      dispatch(setAuth(JSON.parse(localStorage.getItem('user') || '{}')));
    } else {
      console.log('user not found');
      history.push('/login');
    }

    setTimeout(function () {
      setPageLoading(false);
    }, 2000);
  }, [dispatch, history]);

  return (
    <LoadingScreen
      loading={pageLoading}
      bgColor="#fff"
      textColor="#676767"
      logoSrc={`${basename}/static/loading.gif`}
    >
      <SockteProvider>
        {/* @ts-ignore */}
        <RootLayout socket={socket}>
          <Suspense fallback={<div>Loading..</div>}>
            <Switch>
              {router.map(({ exect, path, Component, addProps, permission_name }) => (
                <Route
                  key={path}
                  exact={exect}
                  path={path}
                  render={(props: any) => <Component {...addProps} {...props} />}
                />
              ))}
              <Route exact path="/login" component={Login} />
              <Route exact path="/404" component={NotFound} />
              <Redirect to="/404" />
            </Switch>
          </Suspense>
        </RootLayout>
      </SockteProvider>
      {/* <Iframe
        url="http://localhost:3001/"
        width="1px"
        height="1px"
        id=""
        className=""
        display="block"
        position="relative"
        onLoad={() => {
          console.log('>>>>');
        }}
      /> */}
    </LoadingScreen>
  );
}
export default connect(mapStateToProps)(App);
