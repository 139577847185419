import store from '../store/store';
import { OrderProps } from '../types/states';

export function getOrderById(id: string): OrderProps | undefined {
  const orders = store?.getState()?.orders?.data;
  return orders?.find((item: OrderProps) => Number(item.id) === Number(id));
}
export function getActiveOrderId(): number {
  return store?.getState()?.order?.data.id;
}

export function getActiveOrder(): Record<string, any> {
  return store?.getState()?.order?.data;
}
export function getActiveOrderNumber(): string | undefined {
  const mainStore = store?.getState();
  const order: { order_number?: string; id: number } = mainStore?.order?.data;
  return order?.order_number;
}
export function getOrderQuantity(): number {
  const mainStore = store?.getState();
  const order: { quantity?: number; id: number } = mainStore?.order?.data;
  return order?.quantity || 0;
}
export function getCompanyId(): number | undefined {
  const mainStore = store?.getState();
  const order: { company_id?: number; id: number } = mainStore?.order?.data;
  return order?.company_id;
}
export function getBuyerList(): string[] {
  const orders = store?.getState()?.orders?.data;
  return orders?.reduce((accum: string[], item: { buyer: string }) => {
    if (accum.includes(item.buyer)) {
      return accum;
    }
    return [...accum, item.buyer];
  }, []);
}
export function getStyleList(): string[] {
  const orders = store?.getState()?.orders?.data;
  return orders?.reduce((accum: string[], item: { style: string }) => {
    if (accum.includes(item.style)) {
      return accum;
    }
    return [...accum, item.style];
  }, []);
}
// will return arry of objects like [{value: <buyer>, title: <buyer>}, ...]
export const getBuyerOptions = (buyerStyleData: any): any => {
  const options: any = [];
  const buyer_names: any = [];
  if (buyerStyleData) {
    Object.keys(buyerStyleData).forEach((item) => {
      if (!buyer_names.includes(item)) {
        options.push({ value: item, title: item });
        buyer_names.push(item);
      }
    });
    return options;
  }
};

//will return arry of objects like [{value: <>, title: <>, order_number: <>}, ...]
export const getStyleOptions = (buyerStyleData: any, buyer: any): any => {
  const options: any = [];
  if (buyerStyleData) {
    options.push({
      value: 0,
      title: 'All',
      order_number: 0,
    });
    buyerStyleData[buyer]?.forEach((item: any) => {
      options.push({
        value: item.order,
        title: item.style,
        order_number: item.order_number,
      });
    });
    return options;
  }
  return options;
};
// will return a object like {'buyer_name': [{style: "style_name", order: <order_id>, order_number: 'order_number'}], ...}
export const formatBuyerStyleData = (orders: any): any => {
  return orders?.reduce((acc: any, item: any) => {
    if (item.buyer in acc) {
      acc[item.buyer].push({
        style: item.style,
        order: item.id,
        order_number: item.order_number,
      });
    } else {
      acc[item.buyer] = [{ style: item.style, order: item.id, order_number: item.order_number }];
    }
    return acc;
  }, {});
};

export const getOrderNumberFromStyleOptions = (styleOptions: any, orderId: any): any => {
  let item: any;
  for (item of styleOptions) {
    if (Number(item.value) === Number(orderId)) {
      return item.order_number;
    }
  }
  return '';
};
export const getOrderfromOrderNumber = (orderId: string): Record<string, never> => {
  // @ts-ignore
  const orders = store.getState().orders?.data?.data;
  return orders?.find((item: { id: number }) => item.id === Number(orderId));
};
