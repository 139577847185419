import { QualityControlDataProps } from '../types/states';
import apiClient from './axios';

export async function getQualityControlDataApi(options: any): Promise<string> {
  // return id == 0
  //   ? { count: 0, data: [] }
  //   :
  return apiClient
    .get(
      `/qualitycontrols/buyer/${options.data[0]}/lc/${options.data[1]}/po/${options.data[2]}/style/${options.data[3]}`
    )
    .then((response) => {
      return response.data;
    })
    .catch(() => {
      return false;
    });
}

export async function addQCDataApi(data: FormData): Promise<{ data: QualityControlDataProps }> {
  return apiClient.post(`/qualitycontrols/`, data);
}

export async function updateQCDataApi(
  id: number,
  data: FormData
): Promise<{ data: QualityControlDataProps }> {
  return apiClient.put(`/qualitycontrols/${id}/`, data);
}

export async function deleteQCApi(id: number): Promise<string> {
  return apiClient
    .delete(`/qualitycontrols/${id}/`)
    .then((response) => {
      return response.data;
    })
    .catch(() => {
      return false;
    });
}
