import React from 'react';
import { connect } from 'react-redux';
import SelectInput from '../common/input/SelectInput';
import {
  formatBuyerStyleData,
  getBuyerOptions,
  getOrderById,
  getOrderNumberFromStyleOptions,
  getStyleOptions,
} from '../../utils/orderUtils';
import { setOrder } from '../../store/reducers/orderReducer';
import { setOptions } from '../../store/reducers/optionsReducer';
import { OrderProps } from '../../types/states';
import { useLocation } from 'react-router-dom';
import { setActiveChat } from '../../store/reducers/activeChatReducer';
type StateProps = {
  filter?: any;
  orders?: { data: OrderProps[] };
};
interface IOrderHeaderProps extends StateProps {
  dispatch?: any;
}

const mapStateToProps = ({ filter, orders }: StateProps) => ({ filter, orders });

const OrderHeader = ({ dispatch, filter, orders }: IOrderHeaderProps) => {
  // const [styleOptions, setStyleOptions] = React.useState<any[]>([]);
  const [lcOptions, setLcOptions] = React.useState<any[]>([]);
  const [poOptions, setPoOptions] = React.useState<any[]>([]);
  const [buyerOptions, setBuyerOptions] = React.useState<any[]>([]);
  const [buyerStyle, setBuyerStyle] = React.useState<any[]>([]);
  const [selectIndex, setSelectIndex] = React.useState<any[]>([0, 0, 0, 0]);
  const [selectOptions, setSelectOptions] = React.useState<any[]>(['all', 'all', 'all', 'all']);
  const [styleOptions, setSytleOptions] = React.useState<any>([]);
  const [selectedOrderNumber, setSelectedOrderNumber] = React.useState('');
  const [selectedBuyer, setSelectedBuyer] = React.useState('');
  const [selectedStyle, setSelectedStyle] = React.useState(0);
  const location = useLocation();
  const styleChangeHandler = ({ target }: any) => {
    const orderId = target.value;
    if (orderId == 0) {
      setSelectedBuyer('all');
      setSelectedStyle(0);
      dispatch(setOptions({ data: ['all', 'all', 'all', 'all'] }));
      dispatch(
        setOrder({
          data: { id: 0 },
        })
      );
      return;
    }
    if (orderId && (styleOptions || styleOptions.length > 0)) {
      setSelectedOrderNumber(getOrderNumberFromStyleOptions(styleOptions, orderId));
      const order = getOrderById(orderId);
      if (order) {
        setSelectedBuyer(order.buyer);
        setSelectedStyle(order.id);
      }

      dispatch(setOptions({ data: [order?.buyer, 'all', 'all', order?.style] }));
      dispatch(
        setOrder({
          data: order,
        })
      );
    } else {
      dispatch(setOptions({ data: [selectedBuyer, 'all', 'all', 'all'] }));
      dispatch(
        setOrder({
          data: { id: 0 },
        })
      );
    }
  };

  React.useEffect(() => {
    setSelectedBuyer('all');
    setSelectedStyle(0);
    dispatch(setOptions({ data: ['all', 'all', 'all', 'all'] }));
    dispatch(
      setOrder({
        data: { id: 0 },
      })
    );
    if (
      !(
        location?.pathname == '/contacts' ||
        location?.pathname == '/my-rfqs' ||
        location?.pathname == '/my-queries'
      )
    ) {
      dispatch(setActiveChat({ data: { type: 'NONE', id: 0 } }));
    }
  }, [location]);

  React.useEffect(() => {
    optionSelection(0, 'buyer');
    dispatch(setOptions({ data: ['all', 'all', 'all', 'all'] }));
  }, [filter]);

  React.useEffect(() => {
    const lc_options: any[] = [];
    const po_options: any[] = [];
    const buyer_options: any[] = [];
    const style_options: any[] = [];
    buyer_options.push({ key: 0 + '_buyer', title: 'All', value: 'all' });
    style_options.push({ key: 0 + '_style', title: 'All', value: 0 });
    const buyerStyle = formatBuyerStyleData(orders?.data);
    setBuyerStyle(buyerStyle);
    setBuyerOptions([
      { key: 0 + '_buyer', title: 'All', value: 'all' },
      ...getBuyerOptions(buyerStyle),
    ]);
    setSelectOptions([]);
    setSytleOptions(style_options);
    // orders?.data?.forEach((item) => {
    //   lc_options.push({ key: item?.id + item?.lc, title: item?.lc, value: item?.lc });
    //   po_options.push({ key: item?.id + item?.po, title: item?.po, value: item?.po });
    //   buyer_options.push({ key: item?.id + item?.buyer, title: item?.buyer, value: item?.buyer });
    //   style_options.push({ key: item?.id + item?.style, title: item?.style, value: item?.id });
    // });
    // setLcOptions(lc_options);
    // setPoOptions(po_options);
    // setBuyerOptions(buyer_options);
    const buyer = localStorage.getItem('selectedBuyer');
    const style = localStorage.getItem('selectedStyle');
    if (buyer && style) {
      setSelectedBuyer(buyer);
      setSytleOptions(getStyleOptions(buyerStyle, buyer));
      orders?.data?.forEach((item) => {
        if (item['style'] == style) {
          setSelectedStyle(item['id']);
        }
      });
    }
  }, [orders]);
  const optionSelection = (index: number, filterType: string) => {
    if (filter && filter.loading == false && filter?.data?.buyer_data) {
    }
  };
  const onHandleChange = ({ target }: any, filterType: any) => {
    const index = Number(target.value);
    // optionSelection(index, filterType);
  };
  return (
    <div className="orderHeader pt-3 ps-2 justify-content-between" style={{ paddingRight: '35px' }}>
      <form className="row" key="filter_select_form">
        <div className="col-6 col-lg-6">
          <SelectInput
            size="sm"
            className="me-3 mb-10"
            shadow="md"
            // defaultPlaceholder="All Buyers"
            value={selectedBuyer}
            onChange={(e: any) => {
              setSelectedBuyer(e.target.value);
              setSelectedStyle(0);
              setSytleOptions(getStyleOptions(buyerStyle, e.target.value));
              dispatch(setOptions({ data: [e.target.value, 'all', 'all', 'all'] }));
            }}
            options={buyerOptions}
            key="buyer_select"
          />
        </div>
        <div className="col-6 col-lg-6">
          <SelectInput
            size="sm"
            className="me-3 mb-10"
            shadow="md"
            // defaultPlaceholder="All Styles"
            value={selectedStyle}
            onChange={(target: any) => styleChangeHandler(target)}
            options={styleOptions}
            key="style_select"
          />
        </div>
        {/* <div className="col-6 col-lg-3">
          <SelectInput
            size="sm"
            className="me-3 mb-10"
            shadow="md"
            defaultPlaceholder="All LC/Packages"
            defaultValue=""
            onChange={(target: any) => onHandleChange(target, 'lc')}
            options={lcOptions}
            key="lc_select"
          />
        </div>
        <div className="col-6 col-lg-3">
          <SelectInput
            size="sm"
            className="me-3 mb-10"
            shadow="md"
            defaultPlaceholder="All POs"
            defaultValue=""
            onChange={(target: any) => onHandleChange(target, 'po')}
            options={poOptions}
            key="po_select"
          />
        </div> */}
      </form>
      <div className="moreButton">{/* <HeaderMenu /> */}</div>
    </div>
  );
};

export default connect(mapStateToProps)(OrderHeader);
