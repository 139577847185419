import store from '../store/store';

export function getUserById(id: number): Record<string, never> {
  const users = store.getState().users.data;

  return users && Array.isArray(users)
    ? users?.filter((item: { id: number }) => item.id === Number(id))[0]
    : {};
}

export function getAssignedUsersIdInOrder(): number[] {
  const users = store.getState().usersByOrder.data;

  return users && Array.isArray(users)
    ? users?.map((item: { user_id: number }) => item.user_id)
    : [];
}
