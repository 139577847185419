import moment from 'moment';
import React from 'react';
import { NotificationProps } from '../../../types/states';

type StateProps = {
  notification: NotificationProps;
};
const notification_icon: any = {
  task: 'fas fa-stopwatch',
  note: 'fas fa-clipboard',
  order: 'fas fa-book',
};
const NotificationCard = ({ notification }: StateProps) => {
  return (
    <div className="">
      <div className="notifications-item d-flex mb-20 pe-4">
        <div className="align-items-center d-inline-flex pe-4">
          {/* <img className="d-block" src={icons[types.indexOf(item.type)]} alt="img" /> */}
          <img src="http://localhost:3000/static/user/user2.png" alt="" />
          <span
            style={{
              position: 'relative',
              width: '24px',
              height: '24px',
              borderRadius: '100%',
              background: '#1e6e84',
              top: '20%',
              left: '-24%',
              textAlign: 'center',
              color: 'white',
            }}
          >
            <i className={notification_icon[notification.type]}></i>
          </span>
        </div>
        <div className="text flex-grow-1">
          <h5> {notification?.title} </h5>
          <p> {notification?.body} </p>
        </div>
        <div className="right-side">
          <span>
            {notification.created_at &&
              moment.duration(moment().diff(moment(notification.created_at))).humanize()}{' '}
            ago
          </span>
        </div>
      </div>
      {/* ))} */}
    </div>
  );
};

export default NotificationCard;
