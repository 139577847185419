import React from 'react';
import { ToastContainer } from 'react-toastify';
import LayoutDrawer from '../../../drawer/LayoutDrawer';
import MainHeader from '../../../header/MainHeader';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import { TasksProps } from '../../../../types/states';
import OrderHeader from '../../../header/OrderHeader';
import styles from './mainLayout.module.scss';

type StateProps = {
  tasksByOrder: { data: TasksProps };
};

const mapStateToProps = ({ tasksByOrder }: StateProps) => ({
  tasksByOrder: tasksByOrder.data,
});

interface IMainLayoutProps extends StateProps {
  socket?: any;
  location?: Location;
}

const MainLayout: React.FC<IMainLayoutProps> = ({ children, socket, location }) => {
  const [isDrawerOpen, setIsDrawerOpen] = React.useState(true);
  const up992 = useMediaQuery({
    query: '(min-width: 992px)',
  });
  // const orderHeaderHidePathList = [
  //   '/users',
  //   '/orders',
  //   '/buyer-cm-monitor',
  //   '/style-cm-monitor',
  //   '/messenger',
  // ];

  React.useEffect(() => {
    if (!up992) {
      setIsDrawerOpen(false);
    }
  }, [up992]);

  return (
    <div className={styles.mainLayout}>
      <ToastContainer />
      <div className={`${styles.pageBodyAndHeader} flex-grow-1`}>
        <div className={styles.header}>
          <div className={styles.mainHeader}>
            <MainHeader />
          </div>
        </div>

        <div
          id="page-body-and-drawer"
          className={`${styles.pageBodyAndDrawer} ${isDrawerOpen ? styles.drawerOpen : ''}`}
        >
          <LayoutDrawer
            isDrawerOpen={isDrawerOpen}
            setIsDrawerOpen={setIsDrawerOpen}
            socket={socket}
          />
          <div className={styles.contentWrapper}>
            {!(
              location?.pathname?.includes('/') ||
              location?.pathname == '/users' ||
              location?.pathname?.includes('/orders') ||
              location?.pathname?.includes('messenger') ||
              location?.pathname?.includes('taskmanagement') ||
              location?.pathname?.includes('contacts') ||
              location?.pathname?.includes('buyer-cm-monitor') ||
              location?.pathname?.includes('style-cm-monitor') ||
              location?.pathname?.includes('production-tracker') ||
              location?.pathname?.includes('factory-key-metric') ||
              location?.pathname?.includes('my-rfqs') ||
              location?.pathname?.includes('my-queries') ||
              location?.pathname?.includes('settings') ||
              location?.pathname?.includes('T&A') ||
              location?.pathname?.includes('rfq-list') ||
              location?.pathname?.includes('users') ||
              location?.pathname?.includes('production-planning')
            ) && <OrderHeader />}

            {children}
          </div>
        </div>
      </div>
    </div>
  );
};
// @ts-ignore
export default connect(mapStateToProps)(withRouter(MainLayout));
