import apiClient from './axios';

export async function getNotesApi(options: any): Promise<string> {
  return apiClient
    .get(
      `/notes/buyer/${options.data[0]}/lc/${options.data[1]}/po/${options.data[2]}/style/${options.data[3]}`
    )
    .then((response: any) => {
      return response.data;
    })
    .catch(() => {
      return false;
    });
}

export async function addNotesApi(data: any): Promise<{ data: any }> {
  return apiClient.post(`/notes/`, data);
}
