import React from 'react';
import { connect } from 'react-redux';
import { NotificationProps } from '../../../types/states';
import { FaLessThan } from 'react-icons/fa';
import { loadNotification } from '../../../store/reducers/notificationReducer';
import NotificationCard from './NotificationCard';

type StateProps = {
  notification: { data: NotificationProps[] };
};

interface INotificationProps extends StateProps {
  dispatch: any;
}

const mapStateToProps = ({ notification }: StateProps) => ({
  notification,
});

const Notification = ({ dispatch, notification }: INotificationProps) => {
  // React.useEffect(() => {
  // console.log(notification);
  // dispatch({
  //   type: loadNotification,
  //   // payload: { userId: user_id?.id },
  // });
  // }, []);

  const types = [
    'TNA-NEW-TASK',
    'OR',
    'TNA-COMPLETED-TASK',
    'TNA',
    'TNA-MISSED-TASK',
    'TNA-TODAYS-TASK',
    'MSG',
    'PS',
    'QC',
  ];
  const titles = [
    'Added to T&A',
    'Added to an Order',
    'T&A Status',
    'T&A Alerts',
    'T&A Alerts',
    'T&A Alerts',
    'Unread Messages',
    'Production Alert',
    'QC Alert',
  ];
  const icons = [
    '/static/icons/notification_tna_add.png',
    '/static/icons/notification_order_add.png',
    '/static/icons/notification_tna_status.png',
    '/static/icons/notification_tna_alert.png',
    '/static/icons/notification_tna_alert.png',
    '/static/icons/notification_tna_alert.png',
    '/static/icons/notification_message.png',
    '/static/icons/notification_tna_alert.png',
    '/static/icons/notification_tna_alert.png',
  ];

  // const getDuration = (date) => {
  //   let duration = moment.duration(moment.utc().diff(moment(date)));
  //   let unit = "";
  //   if (duration.asHours() < 1) {
  //     duration = Math.round(duration.asMinutes());
  //     unit = "m";
  //   } else if (duration.asHours() > 24) {
  //     duration = Math.round(duration.asDays());
  //     unit = "d";
  //   } else {
  //     duration = Math.round(duration.asHours());
  //     unit = "h";
  //   }
  //   if (duration > 0) {
  //     duration = duration + unit;
  //   } else {
  //     duration = "now";
  //   }

  //   return duration;
  // };

  return (
    <div className="notifications vw-100 py-4 ps-3 pe-2">
      <div className="">
        {/* <FaLessThan /> */}
        <h4 className="mb-3 text-center mt-3">Notifications</h4>
      </div>
      <div className="notifications-body overflow-auto pe-2">
        {notification?.data?.map((notify) => (
          <NotificationCard key={notify.id} notification={notify} />
        ))}
      </div>
    </div>
  );
};

export default connect(mapStateToProps)(Notification);
