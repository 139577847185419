import { combineReducers } from 'redux';
import orders from './reducers/ordersReducers';
import order from './reducers/orderReducer';
import todaysTasks from './reducers/todaysTasksReducer';
import tasksByOrder from './reducers/tasksByOrderReducer';
import tasksStatusCount from './reducers/tasksStatusCountReducer';
import productionData from './reducers/productionDataReducer';
import productionDataTools from './reducers/productionDataReducerTools';
import qualityControlData from './reducers/qualityControlDataReducer';
import tasks from './reducers/tasksReducer';
import comments from './reducers/commentsReducer';
import notification from './reducers/notificationReducer';
import threads from './reducers/threadsReducer';
import singleThread from './reducers/singleThreadReducer';
import steps from './reducers/stepsReducer';
import users from './reducers/usersReducer';
import auth from './reducers/authReducer';
import usersByOrder from './reducers/usersByOrderReducer';
import costSheet from './reducers/costSheetReducer';
import rfq from './reducers/rfqReducer';
import rfqQuries from './reducers/rfqQuriesReducer';
import btb from './reducers/btbReducer';
import primaryRawMaterial from './reducers/primaryRawMaterialReducer';
import accessories from './reducers/accessoriesReducer';
import commission from './reducers/commissionReducer';
import reports from './reducers/reportsReducer';
import options from './reducers/optionsReducer';
import dashboard from './reducers/dashboardReducer';
import toolsReportData from './reducers/toolsReportDataReducer';
import costSheets from './reducers/toolsCostSheetReducer';
import materials from './reducers/materialsReducer';
import notes from './reducers/noteReducer';
import contacts from './reducers/contactReducer';
import message from './reducers/messageReducer';
import rfqmessage from './reducers/rfqmessageReducer';
import activeChat from './reducers/activeChatReducer';
import company from './reducers/companyInfoReducer';
const reducers = combineReducers({
  accessories,
  auth,
  btb,
  materials,
  costSheets,
  dashboard,
  options,
  comments,
  commission,
  toolsReportData,
  costSheet,
  notification,
  order,
  orders,
  primaryRawMaterial,
  productionData,
  productionDataTools,
  qualityControlData,
  rfq,
  rfqQuries,
  reports,
  singleThread,
  steps,
  tasks,
  tasksByOrder,
  tasksStatusCount,
  threads,
  todaysTasks,
  users,
  usersByOrder,
  notes,
  contacts,
  message,
  rfqmessage,
  activeChat,
  company,
});

export default reducers;
