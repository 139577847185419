import { TasksProps } from '../types/states';
import apiClient from './axios';

export async function getTasksApi(options: any): Promise<string> {
  return apiClient
    .get(
      `/tasks/buyer/${options.data[0]}/lc/${options.data[1]}/po/${options.data[2]}/style/${options.data[3]}`
    )
    .then((response) => {
      return response.data;
    })
    .catch(() => {
      return false;
    });
}

export async function getTandAApi(options: any): Promise<string> {
  return apiClient
    .get(
      `/milestones/buyer/${options.data[0]}/lc/${options.data[1]}/po/${options.data[2]}/style/${options.data[3]}`
    )
    .then((response) => {
      return response.data;
    })
    .catch(() => {
      return false;
    });
}

export async function updateTaskApi(id: number, data: Record<string, never>): Promise<string> {
  return apiClient
    .put(`/tasks/${id}/`, data)
    .then((response) => {
      return response.data;
    })
    .catch(() => {
      return false;
    });
}

export async function updateTasksApi(id: number, data: Record<string, never>): Promise<string> {
  return apiClient
    .put(`/tasks/${id}/`, data)
    .then((response) => {
      return response.data;
    })
    .catch(() => {
      return false;
    });
}

export async function deleteTasksApi(id: number): Promise<string> {
  return apiClient
    .delete(`/tasks/${id}/`)
    .then((response) => {
      return response.data;
    })
    .catch(() => {
      return false;
    });
}

export async function addTaskApi(data: {
  due_date: string;
  due_time: string;
  task_name: string;
  assigned_person: number | false;
  description: string;
}): Promise<{ data: TasksProps }> {
  return apiClient.post(`/tasks/`, data);
}
