import React from 'react';
import styles from './itemCardLayout.module.scss';

type ItemCardLayoutProps = {
  title?: string | false;
  subtitle?: string | false | JSX.Element;
  className?: string;
  style?: any;
  arrow_icon?: any;
};

const ItemCardLayout: React.FC<ItemCardLayoutProps> = ({
  title = false,
  subtitle = false,
  children,
  className,
  arrow_icon,
  style,
  ...props
}) => {
  return (
    <div
      className={`${styles.itemCardLayout} ${className ? className : ''}`}
      style={style}
      {...props}
    >
      {(title || subtitle) && (
        <div className={`${styles.titleAndOther} d-flex align-item-center `}>
          <>
            <div className="title_and_arrow">
              <h5 className="mb-0 text-gray">{title}</h5>
            </div>
            {arrow_icon}
            {subtitle && <div className="others d-inline-flex">{subtitle}</div>}
          </>
        </div>
      )}
      <div className={styles.cardBody}>{children}</div>
    </div>
  );
};

export default ItemCardLayout;
